<template>
  <v-card>
    <v-card-title>
      <v-container fluid class="pa-0">
        <v-row>
          <v-col>
            Ashley Madison whitelisted keywords
          </v-col>
          <v-col>
            <v-btn class="float-right mr-5" color="primary" :disabled="!hasSEAPermissions" @click="showCreateItem()">Add</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-subtitle>
      Keywords which appear here will not be included in the Ashley Madison keywords alerts sent by the Data team.<br />
      NB: this data is stored in the data warehouse, making the response time slower than other screens.
    </v-card-subtitle>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': datatables_rowsPerPageItems,
      }"
      :server-items-length="totalItems"
      class="elevation-1"
    >
      <template v-slot:loading>
        Loading items..
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon :disabled="!hasSEAPermissions" :to="{ name: 'Negative keywords DWH whitelist detail', params: { itemID: item.id }}">
          <v-icon small>edit</v-icon>
        </v-btn>
        <v-btn icon :disabled="!hasSEAPermissions" @click="deleteItem(item.id)">
          <v-icon small>delete</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`footer.page-text`]="props">
        {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
      </template>

    </v-data-table>

    <v-dialog v-model="showModal" persistent max-width="600px">
      <v-card>

        <v-card-title>
          <span class="headline">New keyword</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-form ref="form">
                  <v-text-field v-model="item.keyword" label="Keyword" required :rules="[v => !!v || 'Keyword is required']"></v-text-field>
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeModal()">Close</v-btn>
          <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import auth from '@/auth'
import dataTablesMixin from '@/mixins/datatables'

export default {
  name: 'NKWDwhWhitelistTable',

  data () {
    return {
      headers: [
        { text: 'Keyword', value: 'keyword', sortable: false },
        { text: 'Entry by', value: 'created_by', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      items: [],
      item: {
        keyword: null,
      },
      showModal: false,
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      lsOptions: 'nkwDhwWhitelist_options',
      dataEndpoint: '/a/ext-dwh/am-whitelist-keywords',
    }
  },

  mixins: [dataTablesMixin],

  computed: {
    hasSEAPermissions () {
      if (auth.hasPermission('SEA Basic') || auth.hasPermission('Tech')) {
        return true
      }
      return false
    },
  },

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
  },

  methods: {
    closeModal: function () {
      this.item.keyword = null
      this.showModal = false
    },

    deleteItem: function (id) {
      if (!confirm('Are you sure?')) {
        return
      }

      var myURL = this.dataEndpoint + '/' + id

      this.$http.delete(myURL).then(resp => {
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'DELETE', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
    },

    fetchData: function () {
      this.loading = true
      // xfields not used at the moment: keeping param so that others can be added with & and not ?
      var myURL = this.dataEndpoint + '?xfields=keyword'

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    save: function () {
      // make sure all mandatory fields have a value
      if (!this.$refs.form.validate()) {
        return
      }
      var body = JSON.stringify({'keyword': this.item.keyword})
      this.$http.post(this.dataEndpoint, body).then(resp => {
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.dataEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.closeModal())
    },

    showCreateItem: function () {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      this.showModal = true
    }
  },

  created: function () {
    // get data and refresh regularly to prevent stale data from being shown
    this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>
